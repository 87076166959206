import {IModule} from "src/app/editor/modules";
import {UnwrapRef, watch} from "vue";
import {DefineComponent} from "@vue/runtime-core";
import {account} from "@/app/login";
import ShopPreview from "@/app/editor/modules/shop/v1/ShopPreview.vue";
import ShopSetup from "@/app/editor/modules/shop/v1/ShopSetup.vue";
import {IActivityStatus} from "@/app/editor/types";
import {editor} from "@/app/editor";


export interface IShopConfigShop {
    phone_number: string
    sid: number
    icon: string
    shop_name: string
    tel: string
    area: string
    addr: string
    coordinate: number[]
}

export interface IShopConfig {
    shop: IShopConfigShop
}

export interface IShopSection extends IModule {
    config: UnwrapRef<IShopConfig>
}

export class ShopSectionV1 implements IShopSection {
    readonly i: number
    readonly module = 'shop';
    readonly version = 1;
    readonly title = '店家设置';
    readonly icon = 'men_dian';
    readonly preview: DefineComponent;
    readonly set_up: DefineComponent;
    config: UnwrapRef<IShopConfig>;

    constructor(i: number, c: UnwrapRef<IShopConfig>, as: IActivityStatus) {
        this.i = i
        this.preview = ShopPreview as DefineComponent
        this.set_up = ShopSetup as DefineComponent
        this.config = c
        const s = account.shop

        // 自动更正数据
        if (!this.config.shop) {
            this.config.shop = s ?? {
                phone_number: '',
                addr: "",
                area: "",
                icon: "",
                shop_name: "",
                sid: 0,
                tel: "",
                coordinate: [0, 0]
            }
        }
        as.shop_name = this.config.shop.shop_name
        watch(() => this.config.shop, s => {
            if (editor.activity) {
                editor.activity.data.sid = s.sid
                editor.activity.data.title = editor.activity.previewTitle(editor.activity.data.titles.find(t => t.title_id == editor.activity?.status.title_id)?.title)
                as.shop_name = s.shop_name
            }
        })
    }

    syncData() {
        if (editor.activity) {
            editor.activity.data.sid = this.config.shop.sid
            this.config.shop = editor.activity.data.shops.find(i => i.sid == this.config.shop.sid) ?? this.config.shop
            editor.activity.data.title = editor.activity.previewTitle(editor.activity.data.titles.find(t => t.title_id == editor.activity?.status.title_id)?.title)
            console.log(this.config.shop, editor.activity.data.title)
        }
    }
    validate(): Error | null {
        if (this.config.shop.sid == 0) return new Error('至少要关联一家店')
        return null;
    }
}