import {RouteRecordRaw} from "vue-router";
import EditorSide from "@/app/editor/EditorSide.vue";
import EditorMenu from "@/app/editor/EditorMenu.vue";
import {IModule} from "src/app/editor/modules";
import {Json, mock, random, req} from "@/lib/util";
import {BasicModuleV1, IBasicConfig} from "@/app/editor/modules/basic/v1";
import {IGroupModuleConfig, GroupModuleV1} from "@/app/editor/modules/group/v1";
import {reactive, UnwrapRef, watch} from "vue";
import {message} from "ant-design-vue";
import {IShopConfig, ShopSectionV1} from "@/app/editor/modules/shop/v1";
import {IIntroductionConfig, IntroductionSectionV1} from "@/app/editor/modules/introduction/v1";
import {router} from "@/app";
import {account} from "@/app/login";
import dayjs from "dayjs";
import {IActivityData, IActivityStatus, IModuleData, IThemeImagePreview, variables} from "@/app/editor/types";
import {ForwardModuleV1, IForwardConfig} from "@/app/editor/modules/forward/v1/forward_v1";
import {IRecommendConfig, RecommendModuleV1} from "@/app/editor/modules/recommend/v1/recommend_v1";

export default <Array<RouteRecordRaw>>[
    {
        path: '/editor',
        name: 'editor',
        meta: {requiresAuth: true},
        components: {
            default: () => import(/* webpackChunkName: "editor.view" */ './EditorView.vue'),
            side: EditorSide,
            tabBar: EditorMenu,
        },
        beforeEnter: (): boolean => {
            // !!! ⚠️ 一定要提前清理，不然旧数据会污染新界面
            editor.activity = null
            return true
        }
    }
]

export class Activity {
    readonly id: string = random(9)
    readonly modules: IModule[]
    status: UnwrapRef<IActivityStatus>

    readonly data: UnwrapRef<IActivityData>
    private draft_data: string
    private currentCode: number

    readonly images: { [key: string]: IThemeImagePreview } = reactive({})
    // queue
    private readonly imageLoaderQueue = [] as IThemeImagePreview[]
    private imageLoaderQueueIntervalId = 0
    private imageLoaderQueueIsRunning = false


    constructor(a: IActivityData) {
        this.data = reactive(a)
        this.status = reactive({
            date_range: [dayjs(), dayjs().add(7, 'days')],
            is_distribution: false,
            distribution_list: 'hide',
            buy_button_text: '立即抢购',
            theme_props: {
                theme_id: '',
                theme_options: {},
                applet_thumb_version: 0,
                applet_poster_id: ''
            },
            theme_ids: [],
            title_id: '',
            sell_price: 0,
            rules: [],
            shop_name: ''
        })
        this.modules = this.data.modules.map(s => this.convert(s))
        this.draft_data = JSON.stringify(this.data)


        // 活动日期
        if (!this.data.start_at) this.data.start_at = new Date()
        if (!this.data.end_at) this.data.end_at = new Date()

        if (new Date(this.data.end_at).valueOf() > new Date(this.data.start_at).valueOf()) {
            this.data.end_at = new Date(this.data.end_at)
            this.data.start_at = new Date(this.data.start_at)
        } else {
            this.data.end_at = new Date(this.data.start_at)
            this.data.start_at = new Date(this.data.end_at)
        }
        this.status.date_range = [dayjs(this.data.start_at), dayjs(this.data.end_at)]

        // 其它
        this.data.title = this.previewTitle(this.data.titles.find(t => t.title_id == this.status.title_id)?.title)
        this.currentCode = this.code()

        watch(() => this.status.date_range, (v) => {
            this.data.start_at = v[0]?.toDate()
            this.data.end_at = v[1]?.toDate()
        })

        watch(this.data, () => {
            const code = this.code()
            if (this.currentCode != code) {
                this.currentCode = code
                this.refresh()
            }
        })

        watch(this.status.theme_props, () => {
            const code = this.code()
            if (this.currentCode != code) {
                this.currentCode = code
                this.refresh()
            }
        })

        // console.log('get variable 单独购买价:', this.variable('单独购买价'))
        // console.log('get variable:', this.variable('单独购买价'))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // window.a = this
        // console.log(JSON.stringify(variables))
    }

    previewTitle(title?: string): string {
        return title?.replace(/%(.+?)%/g, v => (this.variable(v.replaceAll('%', '')) ?? '').toString()) ?? ''
    }

    private convert(moduleData: IModuleData): IModule {
        switch (moduleData.module) {
            case 'basic':
                switch (moduleData.version) {
                    case 1:
                        return new BasicModuleV1(moduleData.i, moduleData.config as IBasicConfig, this.status)
                }
                break
            case 'group':
                switch (moduleData.version) {
                    case 1:
                        return new GroupModuleV1(moduleData.i, moduleData.config as IGroupModuleConfig, this.status)
                }
                break
            case 'shop':
                switch (moduleData.version) {
                    case 1:
                        return new ShopSectionV1(moduleData.i, moduleData.config as IShopConfig, this.status)
                }
                break
            case 'introduction':
                switch (moduleData.version) {
                    case 1:
                        return new IntroductionSectionV1(moduleData.i, moduleData.config as IIntroductionConfig, this.status)
                }
                break
            case 'forward':
                switch (moduleData.version) {
                    case 1:
                        return new ForwardModuleV1(moduleData.i, moduleData.config as IForwardConfig, this.status)
                }
                break
            case 'recommend':
                switch (moduleData.version) {
                    case 1:
                        return new RecommendModuleV1(moduleData.i, moduleData.config as IRecommendConfig, this.status)
                }
                break
        }
        return {} as IModule
    }

    validate(): boolean {
        for (let i = 0; i < this.modules.length; i++) {
            const e = this.modules[i].validate()
            if (e != null) {
                message.error({content: e.message})
                return false
            }
        }
        if (!this.data.start_at) {
            message.error({content: '活动开始时间未设置'})
            return false
        }
        if (!this.data.end_at) {
            message.error({content: '活动结束时间未设置'})
            return false
        }
        if (this.data.end_at && this.data.start_at && this.data.end_at.valueOf() < this.data.start_at.valueOf()) {
            message.error({content: '活动结束时间不正确'})
            return false
        }
        if (!this.data.sid || this.data.sid <= 0) {
            message.error({content: '活动门店未设置'})
            return false
        }
        return true
    }

    initDraft() {
        if (account.user?.is_master) return
        if (this.isEdit()) return
        if (!this.data.draft_id) this.data.draft_id = Math.random().toString(36).slice(2)
        this.draft_data = JSON.stringify(this.data)
    }

    saveDraft() {
        if (account.user?.is_master) return
        if (this.isEdit()) return
        if (!this.data.draft_id) this.data.draft_id = Math.random().toString(36).slice(2)

        this.draft_data = JSON.stringify(this.data)
        mock('activity/draft', 'post', {})
        req({
            url: 'activity/draft', method: 'post', data: this.data, success: () => {
                message.success('已存入草稿')
            }
        })
    }

    checkDraft() {
        if (account.user?.is_master) return
        if (this.isEdit()) return
        if (!this.data.draft_id) this.data.draft_id = Math.random().toString(36).slice(2)
        if (this.draft_data == JSON.stringify(this.data)) {
            console.log('checkDraft no change pass')
            return
        }

        this.draft_data = JSON.stringify(this.data)
        mock('activity/draft', 'post', {})
        req({
            url: 'activity/draft', method: 'post', data: this.data, success: () => {
                message.success('已自动存入草稿')
            }
        })
    }

    save(fn: () => void) {
        mock('activity', 'post', {activity_id: /\w{12}/})
        if (this.validate()) {
            const l = message.loading('处理中...', 0)
            req({
                url: 'activity', method: this.isEdit() ? 'put' : 'post', data: this.data, success: rs => {
                    this.draft_data = JSON.stringify(this.data)
                    if (!this.isEdit()) {
                        for (let i = 0; i < account.exp_packages.length; i++) {
                            if (account.exp_packages[i].exp_package_id == this.data.exp_package_id) account.exp_packages[i].balance--
                        }
                    }
                    if (account.user?.is_master) {
                        router.push({path: '/'})
                    } else {
                        router.push({path: '/activity/share', query: {activity_id: rs.activity_id}})
                    }
                }, fail: fn, complete: l
            })
        } else fn()
    }

    offline(fn: () => void) {
        req({
            url: 'template', method: 'delete', params: {activity_id: this.data.activity_id}, success: () => {
                message.success('已下线')
                this.data.is_active = false
                console.log('this.data.is_active', this.data.is_active)
            }, complete: fn
        })
    }

    isEdit(): boolean {
        return !!this.data.activity_id
    }

    isChange(): boolean {
        console.log('isChange', !this.isEdit(), this.draft_data != JSON.stringify(this.data))
        return !this.isEdit() && this.draft_data != JSON.stringify(this.data)
    }

    useExpPackage(id: string) {
        this.data.exp_package_id = id
    }

    tags(): string[] {
        return this.data.tags
    }

    variable(name: string, imageId?: string): string | number | null {
        let v: any = variables[name]
        let d: unknown = null
        try {
            if (imageId) {
                this.status.theme_props.theme_options[imageId].forEach(i => {
                    if (i.title == name) return i.text
                })
            }
            if (v === undefined) {
                return d as string | number | null
            }
            if (v['module']) {
                for (const m of this.data.modules) {
                    if (v['module'][m.module]) {
                        v = v['module'][m.module][m.version]
                        if (Array.isArray(v)) {
                            d = m.config
                            for (const k of v) {
                                d = (d as Json)[k]
                            }
                            /**
                             * 如果不在这里中断循环，会导致已经消费的 变量v 再次消费。当访问二级数组时，会导致崩溃！
                             * 且这里的崩溃会被层层拦截并导致外边的Promise永远无响应！
                             */
                            break
                        }
                    }
                }
            } else if (v['wx']) {
                if (v['wx'][0] == 'share' && v['wx'][1] == 'nick_name') {
                    this.data.modules.forEach(m => {
                        if (m.module == 'shop') d = ((m.config as Json)['shop'] as Json)['shop_name']
                    })
                } else if (v['wx'][0] == 'share' && v['wx'][1] == 'head_img_url') {
                    this.data.modules.forEach(m => {
                        if (m.module == 'shop') d = ((m.config as Json)['shop'] as Json)['icon']
                    })
                }
            }
        } catch (e) {
            console.info(e)
        }

        return d as string | number | null
    }

    genImageTemplateData(): Json {
        const o = {
            modules: this.data.modules,
            wx: {me: {head_img_url: '', nick_name: ''}}
        }
        this.data.modules.forEach(m => {
            if (m.module == 'shop') {
                o.wx.me.head_img_url = (m.config as IShopConfig).shop.icon
                o.wx.me.nick_name = (m.config as IShopConfig).shop.shop_name
            }
        })
        return o
    }


    /**
     * 开始图片类业务
     */

    private code(): number {
        // console.log('code:', Object.keys(variables).map(k => this.variable(k)).join('') + JSON.stringify(this.status.theme_props.theme_options) + this.data.title)
        return this.hash(Object.keys(variables).map(k => this.variable(k)).join('') + JSON.stringify(this.status.theme_props.theme_options) + this.data.title)
    }

    private hash(s: string): number {
        return s.split('').reduce((a, b) => {
            a = ((a << 5) - a) + b.charCodeAt(0);
            return a & a
        }, 0)
    }

    start() {
        this.imageLoaderQueueIntervalId = window.setInterval(async () => {
            if (this.imageLoaderQueueIsRunning) return
            this.imageLoaderQueueIsRunning = true
            while (this.imageLoaderQueue.length) {
                const i = this.imageLoaderQueue.pop()
                if (i) await this.loadImage(i)
            }
            this.imageLoaderQueueIsRunning = false
        }, 1000)
    }

    stop() {
        window.clearInterval(this.imageLoaderQueueIntervalId)
    }

    inQueue(id: string, version: number) {
        if (!(id + version in this.images)) {
            this.images[id + version] = {
                code: 0,
                imageId: id,
                loading: false,
                src: "https://activity.djhdb.cn/media/image/null.jpg",
                version: version
            }
        }
        // console.log(id, version, this.images[id + version])
        this.imageLoaderQueue.push(this.images[id + version])
    }

    refresh() {
        const theme = this.data.themes.find(t => t.theme_id == this.status.theme_props.theme_id)
        if (theme) {
            this.inQueue(theme.head_image_template_id, 0)
            this.inQueue(theme.applet_share_thumb_image_template_id, this.status.theme_props.applet_thumb_version)
            this.inQueue(this.status.theme_props.applet_poster_id, 0)
        }
    }

    loadShowThemes() {
        for (let i = 0; i < this.status.theme_ids.length; i++) {
            const iid = this.data.themes.find(t => t.theme_id == this.status.theme_ids[i])?.head_image_template_id
            // console.log('load show theme image:',iid)
            if (iid) this.inQueue(iid, 0)
        }
    }

    loadAllThemes() {
        for (let i = 0; i < this.data.themes.length; i++) {
            this.inQueue(this.data.themes[i].head_image_template_id, 0)
        }
    }

    loadWxThumbImages(id: string) {
        Array(6).fill(null).map((_, ii) => ii + 1).forEach(i => {
            this.inQueue(id, i)
        })
    }

    private loadImage(pi: IThemeImagePreview): Promise<IThemeImagePreview> {
        const code = this.code()
        if (pi.code == code) return Promise.resolve(pi)
        if (!pi.imageId) {
            pi.loading = false
            pi.src = 'https://activity.djhdb.cn/media/image/null.jpg'
            return Promise.resolve(pi)
        }
        pi.code = code
        pi.loading = true
        return new Promise((resolve, reject) => {
            req({
                url: 'activity/image/preview',
                method: 'post',
                data: {
                    image_id: pi.imageId,
                    version: pi.version,
                    options: this.status.theme_props.theme_options[pi.imageId],
                    title: this.data.title,
                    tags: this.data.tags,
                    ...this.genImageTemplateData()
                },
                config: {responseType: 'blob'},
                success: (rs: Blob) => {
                    pi.loading = false
                    if (rs.size > 1000) pi.src = window.URL.createObjectURL(rs)
                    else pi.code = 0
                    resolve(pi)
                }, fail: e => reject(e), complete: () => pi.loading = false
            })
        })
    }
}

export const editor = {activity: null as Activity | null}
